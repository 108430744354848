<template>
  <form>
    <div class="row d-flex justify-content-center">
      <div class="col-md-12">
        <ValidationObserver ref="card">
          <card title="Update Venue Drinks Recipes">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-5">
                  <ValidationProvider
                    name="customer"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      name="Customer"
                      :error="failed ? 'The Customer field is required' : null"
                      :hasSuccess="passed"
                      label="Customer Name"
                    >
                      <el-select
                        class="select-primary"
                        v-model="customer"
                        placeholder="Customer Name"
                        filterable
                        clearable
                        @change="getVenues(customer.ID)"
                      >
                        <el-option
                          class="select-primary"
                          v-for="item in customers"
                          :key="item.ID"
                          :label="item.NAME"
                          :value="item.ID"
                        >
                        </el-option>
                      </el-select>
                    </fg-input>
                  </ValidationProvider>
                </div>

                <div class="col-md-5">
                  <ValidationProvider
                    name="venue"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      name="Venue"
                      :error="failed ? 'The Venue field is required' : null"
                      :hasSuccess="passed"
                      label="Venue"
                    >
                      <el-select
                        class="select-primary"
                        v-model="venue"
                        placeholder="Choose Venue"
                        @change="getVenueDrinks()"
                        filterable
                        clearable
                      >
                        <el-option
                          class="select-primary"
                          v-for="item in venues"
                          :key="item.ID"
                          :label="item.NAME"
                          :value="item.ID"
                        >
                        </el-option>
                      </el-select>
                    </fg-input>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </card>
        </ValidationObserver>
        <card title="Un-monitored Venue Drinks">
          <div class="col-md-12">
            <div class="row">
              <pg-table
                :tableColumns="VenueDrinkPgTable.tableColumns"
                :tableData="VenueDrinkPgTable.tableData"
                :propsToSearch="VenueDrinkPgTable.propsToSearch"
                :handleCurrentChange="VenueDrinkPgTable.handleCurrentChange"
                :handleTextChange="VenueDrinkPgTable.handleTextChange"
                :handleSelectionChange="VenueDrinkPgTable.handleSelectionChange"
                :paginated="true"
                :searchable="true"
                :preSelected="true"
                :withFieldsFiltering="true"
              >
              </pg-table>
            </div>
          </div>
        </card>
        <!-- end card -->
        <card
          v-if="selectedDrink != null"
          :title="selectedDrink.NAME + ' ' + 'Recipe'"
        >
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <div class="col-md-4">
                  <fg-input
                    name="pluCode"
                    placeholder="PLU Code"
                    label="Plu Code"
                    v-model="selectedDrink.PLU_CODE"
                    :disabled="true"
                    ddon-left-icon="nc-icon nc-single-02"
                  >
                  </fg-input>
                </div>
              </div>
              <pg-table
                :tableColumns="IngredientsPgTable.tableColumns"
                :tableData="selectedDrink.Ingredients"
                :handleTextChange="VenueDrinkPgTable.handleTextChange"
                :handleSelectionChange="IngredientsPgTable.handleSelection"
                :withActions="true"
                :withAddingRows="true"
                :VENUE_DRINK_ID="selectedDrink.ID"
                @on-deleteRow="deleteRow"
              >
              </pg-table>
            </div>
          </div>
        </card>
        <!-- end card -->
        <div class="text-right">
          <button
            type="submit"
            class="btn btn-info btn-fill btn-wd"
            @click.prevent="updateDrinks"
            :disabled="!changesMade"
          >
            Save Updates
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Select, Option } from "element-ui";
import PgTable from "src/pages/Dashboard/Tables/PaginatedTables.vue";
import { initAPI } from "src/gateway/api-instance.js";

extend("required", required);
export default {
  components: {
    PgTable,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      VenueDrinkPgTable: {
        tableColumns: [
          {
            prop: "DRINK_STATUS",
            label: "Monitor",
            minWidth: 80,
            visible: true,
            editable: true,
            editType: "select",
            selectList: [
              { value: "monitored", label: "Monitored" },
              { value: "unmonitored", label: "Un-Monitored" },
              { value: "pending", label: "Pending" }
            ]
          },
          {
            prop: "ID",
            label: "ID",
            visible: true,
            minWidth: 45
          },
          {
            prop: "NAME",
            label: "Drink Name",
            minWidth: 120,
            visible: true
          },
          {
            prop: "PLU_CODE",
            label: "PLU",
            visible: true,
            minWidth: 50
          },
          {
            prop: "TYPE",
            label: "Type",
            minWidth: 75,
            visible: true,
            editable: true,
            editType: "select",
            selectList: [
              { value: "Food", label: "Food" },
              { value: "Liquor", label: "Liquor" },
              { value: "Beer", label: "Beer" },
              { value: "Wine", label: "Wine" },
              { value: "Seltzer", label: "Seltzer" },
              { value: "Hard Cider", label: "Hard Cider" },
              { value: "Other", label: "Other" }
            ]
          },
          {
            prop: "DRINK_CATEGORY",
            label: "Drink Category",
            minWidth: 80,
            visible: true,
            editable: true,
            editType: "select",
            selectList: []
          },
          {
            prop: "VERSION",
            label: "Version",
            minWidth: 60,
            visible: true
          },
          {
            prop: "REF_DRINK_ID",
            label: "Master Drink Ref",
            minWidth: 90,
            visible: true,
            editable: true,
            editType: "select",
            selectList: []
          },
          {
            prop: "CREATED_BY",
            label: "Created by",
            minWidth: 80,
            visible: true
          },
          {
            prop: "CREATED_ON",
            label: "Created on",
            minWidth: 80,
            visible: true
          }
        ],
        tableData: [],
        propsToSearch: [
          "ID",
          "REF_DRINK_ID",
          "NAME",
          "VERSION",
          "TYPE",
          "DRINK_CATEGORY",
          "PLU_CODE",
          "CREATED_ON",
          "CREATED_BY",
          "DRINK_STATUS"
        ],
        handleCurrentChange: this.addIngredients,
        handleSelectionChange: this.changeMonitorStatus,
        handleTextChange: this.handleDrinkChange
      },
      IngredientsPgTable: {
        tableColumns: [
          {
            prop: "VENUE_LIQUOR_ID",
            label: "Ingredient",
            minWidth: 95,
            visible: true,
            editable: true,
            editType: "select",
            selectList: []
          },
          {
            prop: "AMOUNT",
            label: "Amount (Ounces)",
            minWidth: 35,
            visible: true,
            editable: true,
            editType: "input",
            textType: "Number"
          }
        ],
        handleSelection: this.handleIngredientSelectionChange
      },
      customers: [],
      customer: "",
      venues: [],
      venue: "",
      selectedDrink: null,
      masterDrinkList: [],
      masterLiquorList: [],
      changesMade: false
    };
  },
  computed: {
    // This is a computed variable, that it's value is recomputed every time it's used throughout the component.
    Instance() {
      return initAPI(this.$cookies.get("Token"), process.env.VUE_APP_API_URL);
    }
  },
  /*
    - Called when the route that renders this component is about to be navigated away from.
    - Has access to `this` component instance.
  */
  beforeRouteLeave(to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (this.confirmStayInDirtyForm()) {
      next(false);
    } else {
      // Navigate to next view
      next();
    }
  },
  methods: {
    /*
      - To confirm that the user wants to leave and discard the changes.
    */
    confirmLeave() {
      return window.confirm(
        "Do you really want to leave? you have unsaved changes!"
      );
    },
    /*
      - To confirm that the user wants to stay and save the changes.
    */
    confirmStayInDirtyForm() {
      return this.changesMade && !this.confirmLeave();
    },
    /*
      - called when the user tries to navigate away from the page
    */
    beforeWindowUnload(e) {
      if (this.confirmStayInDirtyForm()) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    },
    /* 
      - Called when the user clicks on any row in the drinks table.
      - Checks if the drink's monitor attribute is true, we can add ingredients to it.
      - When the drink status is confirmed, if the drinks' ingredients list is null,
       a function is called to get it from the backend.
      - The selectedDrink variable is set with the clicked row drink, for the paginated-table tag.
    */
    addIngredients(row) {
      this.selectedDrink = null;
      let vm = this;
      if (row.MONITOR == true) {
        let ind = vm.VenueDrinkPgTable.tableData.findIndex(
          item => item.ID === row.ID
        );
        if (vm.VenueDrinkPgTable.tableData[ind].Ingredients.length == 0) {
          this.getDrinkIngredients(row).then(function(response) {
            vm.$set(
              vm.VenueDrinkPgTable.tableData[ind],
              "Ingredients",
              response.data
            );
            vm.selectedDrink = vm.VenueDrinkPgTable.tableData[ind];
          });
        } else {
          vm.selectedDrink = vm.VenueDrinkPgTable.tableData[ind];
        }
      }
    },
    /*
      - Called when the user clickes the delete button in the actions cloumn in the paginated table component, of the drink ingredients.
      - The paginated table component delets the ingrendient for the drink object's list, and emits a trigger to this function.
      - This function's purpose, is to just set the flag of change, for the drink and the whole form.
    */
    deleteRow(row) {
      let index = this.VenueDrinkPgTable.tableData.findIndex(
        item => item.ID === row.VENUE_DRINK_ID
      );
      this.VenueDrinkPgTable.tableData[index].changed = true;
      this.changesMade = true;
    },
    /*
      - Called when the @change attribute in a drinks' table, drop down list is invoked.
      - in this case, what we care about is the monitor, drop-down list.
      - If the drink status's changed to 'unmonitored or 'pending', then it's monitor falg is set to false.
      - If the drink status's changed to 'monitored', then it's monitor falg is set to true.
      - The drink's flag and the overall changesMade flag, are set to true.
    */
    changeMonitorStatus(row) {
      let index = this.VenueDrinkPgTable.tableData.findIndex(
        item => item.ID === row.ID
      );
      this.VenueDrinkPgTable.tableData[index].changed = true;
      if (
        this.VenueDrinkPgTable.tableData[index].DRINK_STATUS == "unmonitored" ||
        this.VenueDrinkPgTable.tableData[index].DRINK_STATUS == "pending"
      )
        this.VenueDrinkPgTable.tableData[index].MONITOR = false;
      else this.VenueDrinkPgTable.tableData[index].MONITOR = true;
      this.changesMade = true;
      this.selectedDrink = null;
      // console.log("monitor change", this.VenueDrinkPgTable.tableData[index]);
    },
    /*
      - Called when an input is made to the input boxes in the drinks' table.
      - Validates if the call is from the drinks or the ingreient's tables, by differentiating between the ID column name.
        (ID for the drinks' table, and VENUE_DRINK_ID for the ingredients' table)
      - The drink's flag and the overall changesMade flag, are set to true.
    */
    handleDrinkChange(row) {
      let index;
      if (typeof row.ID != "undefined")
        index = this.VenueDrinkPgTable.tableData.findIndex(
          item => item.ID === row.ID
        );
      else {
        index = this.VenueDrinkPgTable.tableData.findIndex(
          item => item.ID === row.VENUE_DRINK_ID
        );
      }

      this.VenueDrinkPgTable.tableData[index].changed = true;
      this.changesMade = true;
    },
    /*
      - Called when the @change attribute in a ingredients' table, drop down list is invoked.
      - sets the ingredient VENUE_LIQUOR_VERSION with the chosen master liquor version.
      - The drink's flag and the overall changesMade flag, are set to true.
    */
    handleIngredientSelectionChange(row) {
      let index = this.VenueDrinkPgTable.tableData.findIndex(
        item => item.ID === row.VENUE_DRINK_ID
      );
      this.VenueDrinkPgTable.tableData[index].changed = true;

      index = this.masterLiquorList.findIndex(
        item => item.ID === row.VENUE_LIQUOR_ID
      );
      row.VENUE_LIQUOR_VERSION = this.masterLiquorList[index].VERSION;

      this.changesMade = true;
    },
    /*
      - Called when an input is made to the input boxes in the ingredients' table.
      - The drink's flag and the overall changesMade flag, are set to true.
    */
    handleIngredientChange(row) {
      let index = this.VenueDrinkPgTable.tableData.findIndex(
        item => item.ID === row.ID
      );
      this.VenueDrinkPgTable.tableData[index].changed = true;
      this.changesMade = true;
    },
    /*
      - Calls a GET API to get a list of drink categories from the simplica database.
      - fills the appropriate select lists with the retrieved data.
    */
    getDrinkCategories() {
      this.Instance.get("simplica/drink_categories/").then(response => {
        response.data.forEach((element, index) => {
          this.VenueDrinkPgTable.tableColumns[5].selectList.push({
            value: response.data[index].NAME,
            label: response.data[index].NAME
          });
        });
      });
    },
    /*
     - Calls a GET API to get a list of drink ingrdients from the simplica database with the drink Id.
    */
    getDrinkIngredients(row) {
      this.Instance.get("simplica/drinks_ing?drinkId=" + row.ID).then(
        response => {
          return response;
        }
      );
    },
    /*
      - Calls a GET API to get a list of master drinks from the simplica database.
      - fills the global master drink list and the appropriate select lists, with the retrieved data
    */
    getMsterDrinks() {
      this.Instance.get("simplica/master_drinks/", {
        headers: { Authorization: "Bearer " + this.$cookies.get("Token") }
      }).then(response => {
        let templist = [];
        response.data.forEach((element, index) => {
          templist.push({
            value: response.data[index].ID,
            label: response.data[index].NAME
          });
          this.VenueDrinkPgTable.tableColumns[7].selectList = templist;
        });
      });
    },
    /*
      - Calls a GET API to get a list of master liqours from the simplica database.
      - sets the liquor name to it's BRAND + ALCOHOL_NAME if it's found.
      - fills the appropriate select lists with the retrieved data.
    */
    getMasterLiqours() {
      this.Instance.get("simplica/master_liquors?venue_id=" + this.venue).then(
        response => {
          response.data.forEach((element, index) => {
            let name;
            if (response.data[index].ALCOHOL_NAME == null)
              name = response.data[index].BRAND;
            else{
              name =
                response.data[index].BRAND +
                " - " +
                response.data[index].ALCOHOL_NAME;
            }
            if(response.data[index].REMOVED == "F"){
              this.IngredientsPgTable.tableColumns[0].selectList.push({
                value: response.data[index].ID,
                label: name
              });
            }
          });
          this.masterLiquorList = response.data.filter(item => {
            return item.REMOVED === "F";
          });
          //console.log(this.IngredientsPgTable.tableColumns[0].selectList)
        }
      );
    },
    /*
      - Calls a GET API to get a list of old un-monitored venue drinks only from the simplica database.
      - fills the venueDrinks list with the retrived data, and calls a function to append the pending drinks to it.
      - Calls the ge functions to get the drink categories, mater drinks and liqours.
    */
    getVenueDrinks() {
      this.getMsterDrinks();
      this.getDrinkCategories();
      this.Instance.get(
        "/simplica/drinks?venueId=" + this.venue + "&version=1" + "&monitor=0"
      ).then(response => {
        this.VenueDrinkPgTable.tableData = response.data;

        this.VenueDrinkPgTable.tableData.forEach((element, index) => {
          this.$set(this.VenueDrinkPgTable.tableData[index], "changed", false);
          this.$set(this.VenueDrinkPgTable.tableData[index], "Ingredients", []);

          if (this.VenueDrinkPgTable.tableData[index].MONITOR == "true") {
            this.VenueDrinkPgTable.tableData[index].MONITOR = true;
          } else if (
            this.VenueDrinkPgTable.tableData[index].MONITOR == "false"
          ) {
            this.VenueDrinkPgTable.tableData[index].MONITOR = false;
          }
        });
      });
      this.getMasterLiqours();
    },
    /*
      - Calls a GET API to get a list of customers from the simplica database.
      - fills the appropriate select lists with the retrieved data.
    */
    getCustomers() {
      this.Instance.get("simplica/customers").then(response => {
        this.customers = response.data;
      });
    },
    /*
      - Calls a GET API to get a list of venues from the simplica database.
      - fills the appropriate select lists with the retrieved data.
    */
    getVenues() {
      this.Instance.get("simplica/venues?customerId=" + this.customer).then(
        response => {
          this.venues = response.data;
        }
      );
      this.venue = "";
    },
    /*
      - Called when the user, clicks the "Save Updates" button in the UI.
      - Filters the VenueDrinkPgTable with the changed flag, to get only the drinks with the changed flag set as true.
      - Calls the simplica/updateDrinks/ POST API to update the cahnged drinks, in the backend.
    */
    updateDrinks() {
      let data = this.VenueDrinkPgTable.tableData.filter(item => {
        return item.changed === true;
      });

      let vm = this;
      data.forEach((element, index)=>{
        this.$set(
          data[index],
          "MODIFIED_BY",
          vm.$cookies.get("username")
        );
      });
      this.Instance.post("simplica/updateDrinks/", data)
        .then(function(response) {
          console.log(response);
          vm.$router.go();
        })
        .catch(function(error) {
          console.error(error);
        });
      this.changesMade = false;
    }
  },
  mounted() {
    this.getCustomers();
  },
  created() {
    // is called when the user tries to leave/close the page with usaved changes.
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  }
};
</script>
<style></style>
